<style lang="less" scoped>
/deep/ #depositReceiptUrl,
#pratiqueUrl,
#nucleinUrl {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
/deep/ .avatar-uploader > .ant-upload {
  width: 110px;
  height: 110px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
/deep/ .ant-upload{
  padding: 0 !important;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-form-item1 {
  min-height: 0 !important;
  width: auto !important;
}
/deep/ .ant-form-item {
  margin-right: 0 !important;
}
.form {
  display: flex;
  flex-wrap: wrap;
  > .ant-form-item {
    width: 50% !important;
    min-height: 60px;
    margin-right: 0 !important;
    /deep/ .ant-radio-wrapper {
      margin-right: 0 !important;
    }
  }
}
.multi-form {
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
}
/deep/ .ant-form-item-control-wrapper {
  width: 70%;
}
/deep/ .ant-form-item-label {
  width: 90px !important;
  text-align: left !important;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0 !important;
}
/deep/ .ant-radio-wrapper {
  > span{
    padding-left: 3px !important;
  }
}
.pick{
  width: 110px;
  img{
    border-radius: 5px;
  }
}
</style>
<template>
  <a-form layout="inline"  :form="form" class="form">
  

     

    <a-form-item label="温区">
      <a-select
        v-decorator="['temperatureZone', { rules: [{ required: true, message: '温区' }] }]"
        style="width: 90%"
        placeholder="温区"
      >
        <a-select-option :key="index" v-for="(item, index) in temperatureZoneData" :value="item.enumValue">
          {{ item.enumName }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item layout="inline" label="回单类型">
      <a-select
        v-decorator="['receiptType', { initialValue: 0}]"
        style="width: 90%"
        placeholder="回单类型"
      >
        <a-select-option :key="0"  :value="0">
          无回单
        </a-select-option>
        <a-select-option :key="index" v-for="(item, index) in receiptTypeData" :value="item.enumValue">
          {{ item.enumName }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item layout="inline" label="是否保价">
      <a-radio-group      
        v-decorator="['isInsured', { initialValue: 0, rules: [{ required: true }] }]"
      >
        <a-radio :key="index" v-for="(item, index) in isInsureData" :value="item.enumValue">
          {{ item.enumName }}</a-radio
        >
      </a-radio-group>
    </a-form-item>

    <a-form-item label="声明价值">
      <a-input-number
        :min="1"
        :max="500000"
        :step="1"
        :precision="0"
        v-decorator="['insuredPrice',{initialValue:''}]"
        style="width: 90%"
      />
    </a-form-item>
    
    <a-form-item layout="inline" label="是否进口">
      <a-radio-group
        @change="selectImport($event)"
        v-decorator="['isImport', { initialValue: 0, rules: [{ required: true }] }]"
      >
        <a-radio :key="index" v-for="(item, index) in isImportData" :value="item.enumValue">
          {{ item.enumName }}</a-radio
        >
      </a-radio-group>
    </a-form-item>

    <div layout="inline" label="" v-if="uploadShow">
      <a-list :grid="{ gutter: 20, column: 3 }">
        <a-list-item>
          <a-upload
            name="depositReceipt"
            list-type="picture-card"
            :max-count="1"
            class="avatar-uploader"
            :show-upload-list="false"
            :customRequest="uploadImg"
            accept=".jpg,.png,.jpeg"
          >
            <a-form-item layout="inline" :class="[uploadShow ? 'ant-form-item1' : '']">
              <a-input v-show="false" v-decorator="['depositReceipt', { initialValue: '' ,rules: [{ required: true,message:'关单' }]}]" />
            </a-form-item>
            <img
              id="depositReceiptUrl"
              width="100px"
              height="100px"
              v-if="depositReceiptUrl"
              :src="depositReceiptUrl"
              alt="avatar"
            />
            <div v-else>
              <a-icon :type="imgLoading ? 'imgLoading' : 'plus'" />
              <div class="ant-upload-text">关单</div>
            </div>
          </a-upload>
        </a-list-item>

        <a-list-item>
          <a-upload
            name="pratique"
            list-type="picture-card"
            :max-count="1"
            class="avatar-uploader"
            :show-upload-list="false"
            :customRequest="uploadImg"
            accept=".jpg,.png,.jpeg"
          >
            <a-form-item layout="inline" :class="[uploadShow ? 'ant-form-item1' : '']">
              <a-input v-show="false" v-decorator="['pratique', { initialValue: '',rules: [{ required: true,message:'检验检疫证' }]}]" />
            </a-form-item>

            <img id="pratiqueUrl" v-if="pratiqueUrl" width="100px" height="100px" :src="pratiqueUrl" alt="avatar" />
            <div v-else>
              <a-icon :type="imgLoading ? 'imgLoading' : 'plus'" />
              <div class="ant-upload-text">检验检疫证</div>
            </div>
          </a-upload>
        </a-list-item>

        <a-list-item>
          <a-upload
            name="nuclein"
            list-type="picture-card"
            :max-count="1"
            mark="nuclein"
            class="avatar-uploader"
            :show-upload-list="false"
            :customRequest="uploadImg"
            accept=".jpg,.png,.jpeg"
          >
            <a-form-item layout="inline" :class="[uploadShow ? 'ant-form-item1' : '']">
              <a-input v-show="false" v-decorator="['nuclein', { initialValue: '',rules: [{ required: true,message:'核酸检测报告'}]}]" />
            </a-form-item>
            <img v-if="nucleinUrl" id="nucleinUrl" width="100px" height="100px" :src="nucleinUrl" alt="avatar" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">核酸检测报告</div>
            </div>
          </a-upload>
        </a-list-item>
      </a-list>
    </div>
    <br />
    <a-form-item layout="inline" label="提货类型">
      <a-radio-group  v-decorator="['pickupType', { initialValue: 1, rules: [{ required: true }] }]" @change="pickChange($event)">
        <a-radio :key="index" v-for="(item, index) in pickupTypeData" :value="item.enumValue">{{
          item.enumName
        }}</a-radio>
      </a-radio-group>
    </a-form-item>
    
    <a-form-item layout="inline" label="派货类型">
      <a-radio-group v-decorator="['dispatchType', { initialValue: 1, rules: [{ required: true }] }]">
        <a-radio :key="index" v-for="(item, index) in dispatchTypeData" :value="item.enumValue">{{
          item.enumName
        }}</a-radio>
      </a-radio-group>
    </a-form-item>
    <br />

    <a-form-item layout="inline" label="派货入仓" v-show="warehouseShow">
      <a-radio-group v-decorator="['isDispatchWarehouseFee', { initialValue: 0, rules: [{ required: true }] }]">
        <a-radio :key="index" v-for="(item, index) in isPutWarehouseData" :value="item.enumValue">{{
          item.enumName
        }}</a-radio>
      </a-radio-group>
    </a-form-item>

    <br />

    <a-form-item layout="inline" label="付款方式">
      <a-select
        @change="paymentWayChange($event)"
        v-decorator="['paymentWay', { rules: [{ required: true, message: '付款方式' }] }]"
        style="width: 90%"
        placeholder="付款方式"
      >
        <a-select-option :key="index" v-for="(item, index) in paymentWayData" :value="item.enumValue" v-if="item.enumValue != 6">
          {{ item.enumName }}
        </a-select-option>
      </a-select>
    </a-form-item>

   <a-form-item layout="inline" label="企业客户" v-if="companyCustomerCodeShow">
      <a-select        
        v-decorator="['paymentCustomerCode', { rules: [{ required: true, message: '企业客户' }] }]"
        @change="paymentCustomerChange($event)"
        style="width: 90%"
        placeholder="企业客户"
      >
        <a-select-option :key="item.code" v-for="(item, index) in companyCustomerCodeList" :value="item.code">
          {{ item.name }}
        </a-select-option>
      </a-select>
     
    </a-form-item>
 

    <a-form-item layout="inline" label="是否收装卸费" v-if="enterpriseFeeShow">
      <a-radio-group
        @change="handlingFeeChange($event)"            
        v-decorator="['isHandlingFee', { initialValue: 0}]"    
      >
        <a-radio :key="index" v-for="(item, index) in isHandlingFeeData" :value="item.enumValue"> {{ item.enumName }}</a-radio>
      </a-radio-group>
    </a-form-item>

     <a-form-item layout="inline" label="装卸费" v-if="isHandlingFeeOK">
      <a-input-number
        :min="0"
        :max="500000"
        :step="1"
        :precision="2"
        v-decorator="['handlingFee',{rules: [{ required: true, message: '装卸费' }],initialValue:0}]"
        style="width: 90%"
      />
    </a-form-item>

    <a-form-item layout="inline" label="是否收分拣费" v-if="enterpriseFeeShow">
      <a-radio-group
        @change="sortingFeeChange($event)"            
        v-decorator="['isSortingFee', { initialValue: 0}]"    
      >
        <a-radio :key="index" v-for="(item, index) in isSortingFeeData" :value="item.enumValue"> {{ item.enumName }}</a-radio>
      </a-radio-group>
    </a-form-item>

     <a-form-item label="分拣费" v-if="isSortingFeeOK">
      <a-input-number
        :min="0"
        :max="500000"
        :step="1"
        :precision="2"
        v-decorator="['sortingFee',{rules: [{ required: true, message: '分拣费' }],initialValue:0}]"
        style="width: 90%"
      />
    </a-form-item>

   

    <a-form-item layout="inline" label="是否收托盘费" v-if="enterpriseFeeShow">
      <a-radio-group
        @change="palletFeeChange($event.target.value)"         
        v-decorator="['isPalletFee', { initialValue: 0}]"    
      >
        <a-radio :key="index" v-for="(item, index) in isPalletFeeData" :value="item.enumValue"> {{ item.enumName }}</a-radio>
      </a-radio-group>
    </a-form-item>

     <a-form-item label="托盘费" v-if="palletFeeOK">
      <a-input-number
        :min="0"
        :max="500000"
        :step="1"
        :precision="2"
        v-decorator="['palletFee',{rules: [{ required: true, message: '装卸费' }],initialValue:0}]"
        style="width: 90%"
      />
    </a-form-item>

   <a-col :md="12" :sm="12" class="goods-input" v-if="palletFeeOK">
        <a-form-item label="托盘个数" class="total-volume goods-input">
          <a-input-number  :min="0"
            :max="99999"
            :step="1"
            :precision="0"   v-decorator="['palletNum', { rules: [{ required: true, message: '装卸费' }],initialValue: 0}]" id="palletNum" />          
        </a-form-item>
   </a-col>


    <a-form-item layout="inline" label="提货单">
      <a-radio-group
        @change="selectPickupBills($event)"   
        
        v-decorator="['pickupBillsBool', { initialValue: 0}]"    
      >
        <a-radio :key="index" v-for="(item, index) in isPickupBillData" :value="item.enumValue"> {{ item.enumName }}</a-radio>
      </a-radio-group>
    </a-form-item>


    <a-form-item layout="inline"  v-if="companyCustomerCodeShow">
       <a-input v-show="false" v-decorator="['tbCustomerId', {initialValue: ''}]" />
    </a-form-item>

    <div layout="inline" label="" v-show="pickupBillsShow">
      <a-list :grid="{ gutter: 20, column: 3 }">
        <a-form-item v-show="false">
      <a-input  v-decorator="['pickupBills',{ initialValue: ''}]"   />
    </a-form-item>
        <a-list-item v-for="(item,index) in pickUpBillsImgArr" :key="index" class="pick">
          <img              
              width="100px"
              height="100px"              
              :src="item"
              alt="avatar"
            />  
        </a-list-item>

        <a-list-item v-if="pickUpBillsImgArr.length < 5">
          <a-upload
            name="pickupBills"
            list-type="picture-card"
            :max-count="1"
            class="avatar-uploader"
            :show-upload-list="false"
            :customRequest="uploadImg"
            accept=".jpg,.png,.jpeg"
          >            
            <div>
              <a-icon :type="imgLoading ? 'imgLoading' : 'plus'" />
              <div class="ant-upload-text">提货单</div>
            </div>
          </a-upload>
        </a-list-item>

        
      </a-list>
    </div>

  </a-form>
</template>

<script>
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { put } from '@/utils/upload'
import { quotationCustomerPriceList,findListCustomer } from '@/api/manage'
import Vue from 'vue'


export default {
  name: 'OrderForm',
  components: {},
  props: {
   
  },
  data() {
    return {
      temperatureZoneData: {},
      receiptTypeData: {},
      paymentWayData: {},
      isImportData: {},
      pickupTypeData: {},
      dispatchTypeData: {},
      imgLoading: false,
      nucleinUrl: '',
      pratiqueUrl: '',
      depositReceiptUrl: '',
      uploadShow: false,  
      warehouseShow:true,    
      companyCustomerCodeShow: false,
      enterpriseFeeShow: false,
      companyCustomerCodeList:[],
      orderEnumerationData:[], 
      goodsEnumerationData:[],   
      pickUpBillsArr:[],
      pickUpBillsImgArr:[],
      pickupBillsShow:false,
      isInsureData:[],
      isHandlingFeeData:[],
      isPalletFeeData:[],
      isPutWarehouseData:[],
      isSortingFeeData:[],
      isPickupBillData:[{'enumValue':0,'enumName':'否'},{'enumValue':1,'enumName':'是'}],
      palletFeeOK:false,
      isHandlingFeeOK:false,
      palletNuRule:0,            
      isSortingFeeOK:false,
      form: this.$form.createForm(this),
    }
  },
  mounted() {},
  methods: {
    order(){
      this.companyCustomerCodeShow = false      
      this.form.resetFields()
    },
    orderLoad(orderEnumerationData) {
       this.receiptTypeData = orderEnumerationData.receiptType
       this.paymentWayData = orderEnumerationData.paymentWay
       this.pickupTypeData = orderEnumerationData.pickupType
       this.dispatchTypeData = orderEnumerationData.dispatchType      
       this.isInsureData =  orderEnumerationData.isInsured 
       this.isHandlingFeeData = orderEnumerationData.isHandlingFee
       this.isPalletFeeData = orderEnumerationData.isPalletFee 
       this.isPutWarehouseData = orderEnumerationData.isDispatchWarehouseFee 
       this.isSortingFeeData = orderEnumerationData.isSortingFee 
    },
    goodsLoad(goodsEnumerationData) {
      this.temperatureZoneData = goodsEnumerationData.temperatureZone
      this.isImportData = goodsEnumerationData.isImport
      console.log('temperatureZoneData', this.temperatureZoneData)
    },
    setCustomerInfo(customerInfo){
      console.log('customerInfo',customerInfo)
      if(customerInfo.settlementType == 1){            
          var $paymentWay = 3
      }else{            
          var $paymentWay = 4
      }
      this.form.setFieldsValue({'paymentWay':$paymentWay})
      this.paymentWayChange($paymentWay)
      setTimeout(()=>{                        
          this.form.setFieldsValue({'paymentCustomerCode':customerInfo.code})
          this.paymentCustomerChange(customerInfo.code)
      },500)         
    },
    uploadImg(action) {
      let that = this
      this.imgLoading = true
      const file = action.file
      var mark = action.filename
      console.log('action', action)
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          if (mark == 'nuclein') {
            this.form.setFieldsValue({
              nuclein: fileName,
            })
          } else if (mark == 'pratique') {
            this.form.setFieldsValue({
              pratique: fileName,
            })
          } else if (mark == 'depositReceipt') {
            this.form.setFieldsValue({
              depositReceipt: fileName,
            })
          }else{            
            this.pickUpBillsArr.push(fileName)
            var pickupBills = this.pickUpBillsArr.join(',')
            this.form.setFieldsValue({
              pickupBills: pickupBills,
            })
          }
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, mark)
        })
        .catch((e) => {
          console.info(e)
        })
    },

    async getimgblob(url, id) {
      let token = Vue.ls.get(ACCESS_TOKEN)
      let request = new XMLHttpRequest()
      request.timeout = 9000
      request.responseType = 'blob'
      request.open('get', url, true)
      // 携带请求头
      request.setRequestHeader('token', token)
      request.onreadystatechange = (e) => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
          console.log('response', request.response)
          var imageUrl = URL.createObjectURL(request.response)
          if (id == 'nuclein') {
            this.nucleinUrl = imageUrl
          } else if (id == 'pratique') {
            this.pratiqueUrl = imageUrl
          } else if (id == 'depositReceipt') {
            this.depositReceiptUrl = imageUrl
          }else{
            this.pickUpBillsImgArr.push(imageUrl)
          }
        } else {
          return ''
        }
      }
      request.send(null)
    },

    selectImport($event) {
      var isImport = $event.target.value
      if (isImport == 1) {
        this.uploadShow = true
      } else {
        this.uploadShow = false
      }
    },
    selectPickupBills($event){
        var isPickUpBills = $event.target.value
        if (isPickUpBills == 1) {
          this.pickupBillsShow = true
        } else {           
          this.pickupBillsShow = false
        }
    },
    handlingFeeChange($handlingFee){

        let isHandlingFee = $handlingFee.target.value       
        if(isHandlingFee == 1){
          this.isHandlingFeeOK = true
        }else{
          this.isHandlingFeeOK = false
        }
    },
    palletFeeChange($isPalletFee){      
        let that = this         
        let volume = that.$parent.$parent.$parent.$parent.$refs.goods.form.getFieldValue('volume')          
        if($isPalletFee == 1){
            that.palletFeeOK = true            
            if(volume != undefined){
                let palletNum = 0;                
                if(this.palletNuRule > 0){
                    palletNum = Math.ceil(volume/this.palletNuRule)                
                }

                const {
                   form: { setFieldsValue },
                } = that
                setTimeout(()=>{
                     that.form.setFieldsValue({'palletNum':palletNum})
                },200)                
            }        
        }else{
            that.palletFeeOK = false
        }
        
      
    },
    paymentCustomerChange($code){
        
        let isHandlingFee = 0
        let isPalletFee = 0
        let handlingUnitPrice = 0
        let palletUnitPrice = 0
        let tbCustomerId = 0
        let isSortingFee = 0
        let sortingFee = 0
    
        for(var i=0;i<this.companyCustomerCodeList.length;i++){
          if($code == this.companyCustomerCodeList[i].code){                                          
                isHandlingFee = this.companyCustomerCodeList[i].isHandlingFee
                isPalletFee = this.companyCustomerCodeList[i].isPalletFee
                handlingUnitPrice = this.companyCustomerCodeList[i].handlingUnitPrice
                palletUnitPrice = this.companyCustomerCodeList[i].palletUnitPrice
                tbCustomerId = this.companyCustomerCodeList[i].tbCustomerId
                this.palletNuRule = this.companyCustomerCodeList[i].palletNuRule
                isSortingFee = this.companyCustomerCodeList[i].isSortingFee
                sortingFee = this.companyCustomerCodeList[i].sortingFee
                break;
          }
        }
         this.form.setFieldsValue({'tbCustomerId':tbCustomerId})
        //托盘费
        if(isPalletFee == 1){
           this.palletFeeOK = true
           let volume = this.$parent.$parent.$parent.$parent.$refs.goods.form.getFieldValue('volume') 
           if(volume != undefined){
                let palletNum = 0              
                if(this.palletNuRule > 0){
                    palletNum = Math.ceil(volume/this.palletNuRule)                                
                }                                        
                setTimeout(()=>{
                     this.form.setFieldsValue({'palletNum':palletNum})
                },200)                
           }
           setTimeout(()=>{
            this.form.setFieldsValue({'palletFee':palletUnitPrice})
           },200)           
        }else{
           this.palletFeeOK = false
        } 
        
        //装卸费
        if(isHandlingFee == 1){
          this.isHandlingFeeOK = true
          setTimeout(()=>{
            this.form.setFieldsValue({'handlingFee':handlingUnitPrice})
           },200)          
        }else{
           this.isHandlingFeeOK = false
        }

        console.log('isSortingFee',isSortingFee) 
        //分拣费
        if(isSortingFee == 1){
          this.isSortingFeeOK = true
          setTimeout(()=>{
            this.form.setFieldsValue({'sortingFee':sortingFee})
           },200)          
        }else{
           this.isSortingFeeOK = false
        }

        setTimeout(()=>{
          this.form.setFieldsValue({'isPalletFee':isPalletFee,'isHandlingFee':isHandlingFee,'isSortingFee':isSortingFee})
        },300)                                   
    },
    pickChange($event){
      
      if($event.target.value == 2){
        
        this.warehouseShow = false
      }else{

        this.warehouseShow = true
      }
      
    },
    sortingFeeChange($event){
      if($event.target.value == 1){

        this.isSortingFeeOK = true        
      }else{

        this.isSortingFeeOK = false          
      }          
    },
    paymentWayChange($paymentWay) {
      this.companyCustomerCodeList = []      
      if ($paymentWay == '5') {
          this.companyCustomerCodeShow = true
          this.enterpriseFeeShow = false
          this.palletFeeOK = false
          this.isHandlingFeeOK = false
         var parameter = { "customerType": 3 }
         quotationCustomerPriceList(parameter)
         .then(res => {
            if(res.code == 0){
              console.log('quotationCustomerPriceList',res)   
              for(var i=0;i<res.result.length;i++){
                this.companyCustomerCodeList.push({"code":res.result[i].customerCode,"name":res.result[i].name})
              }             
              this.form.setFieldsValue({
                paymentCustomerCode: '',
              })
            }else{
              this.$notification.error({
                message: '服务器繁忙',
                description: res.message
              })
            }
         })       
      }else if($paymentWay == '3' || $paymentWay == '4'){
          this.companyCustomerCodeShow = true
          this.enterpriseFeeShow = true
         var settlementType = 1 
        if($paymentWay == '4'){
          settlementType = 2
        }
        var parameter = { "type": 1, "settlementType":settlementType,"status":1}
         findListCustomer(parameter)
         .then(res => {
            if(res.code == 0){
              
              for(var i=0;i<res.result.length;i++){
                this.companyCustomerCodeList.push({'tbCustomerId':res.result[i].id,"code":res.result[i].code,"name":res.result[i].nickname,"isHandlingFee":res.result[i].isHandlingFee,"isPalletFee":res.result[i].isPalletFee,"handlingUnitPrice":res.result[i].handlingUnitPrice,"palletUnitPrice":res.result[i].palletUnitPrice,"palletNuRule":res.result[i].palletNuRule,"isSortingFee":res.result[i].isSortingFee,"sortingFee":res.result[i].sortingFee})
              }
            
              this.form.setFieldsValue({
                paymentCustomerCode: '',
              })
            }else{
              this.$notification.error({
                message: '服务器繁忙',
                description: res.message
              })
            }
         })               
      }else {
        this.palletFeeOK = false
        this.isHandlingFeeOK = false
        this.companyCustomerCodeShow = false
        this.enterpriseFeeShow = false
      }
    },
  },
}
</script>
