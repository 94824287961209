<style lang="less" scoped>
.extra-wrapper {
  line-height: 55px;
  padding-right: 24px;

  .extra-item {
    display: inline-block;
    margin-right: 24px;

    a {
      margin-left: 24px;
    }
  }
}

.antd-pro-pages-dashboard-analysis-twoColLayout {
  position: relative;
  display: flex;
  display: block;
  flex-flow: row wrap;
  overflow: hidden;
}
.page-header-index-wide{
  padding-bottom: 50px;
}
.antd-pro-pages-dashboard-analysis-salesCard {
  height: calc(100% - 24px);
  /deep/ .ant-card-head {
    position: relative;
  }
}

.dashboard-analysis-iconGroup {
  i {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: color 0.32s;
    color: black;
  }
}
.analysis-salesTypeRadio {
  position: absolute;
  right: 54px;
  bottom: 12px;
}

</style>

<style lang="less" scoped>
.input-left {
  width: 100px !important;
}
/deep/ .ant-upload-select-picture-card {
  min-width: 80px !important;
  min-height: 80px !important;
}
/deep/ .ant-form-item-with-help {
  margin-bottom: 0 !important;
  line-height: 25px;
}
/deep/ .ant-card-body {
  padding-bottom: 10px !important;
}
/deep/ .length-width-and-height {
  margin-left: 0 !important;
  display: flex;
  .ant-form-item {
    min-height: 60px;
    margin-right: 0 !important;
  }
}

</style>
<template>
  <div class="page-header-index-wide">
    <div class="antd-pro-pages-dashboard-analysis-twoColLayout" :class="isDesktop() ? 'desktop' : ''">
      <div class="table-operator">
        <a-button type="primary" @click="validate()" v-preventReClick>查看报价</a-button>
      </div>

       <a-row :gutter="48" type="flex" :style="{ marginTop: '24px' }">
        <a-col :xl="24" :lg="48" :md="48" :sm="48" :xs="48">
              <a-card class="card"  :bordered="false"> 
              <a-form layout="inline" class="formInquire" :form="mainForm">
                   <a-form-item label="请选择下单客户">
                        <a-select @change="customerChange($event)"  v-decorator="['tbCustomerId', { initialValue: 0}]"  style="width:100%;"  placeholder="客户名称">
                          <a-select-option :key="0"  :value="0">
                              零担下单
                          </a-select-option>                 
                        <a-select-option v-for="customerCode in customerCodeList" :key ="customerCode.code" :value="customerCode.code">
                            {{ customerCode.nickname }}
                        </a-select-option>        
                        </a-select>
                    </a-form-item>
              </a-form>  
              </a-card> 
        </a-col>
      </a-row>  

      

      <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="card" title="寄方" :bordered="false">  <a slot="extra" @click="shipperAddress()">寄件人地址簿</a>
            <send-form ref="send" :showSubmit="false" :SendTheIndex="'1'" />
          </a-card>
        </a-col>

        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="card" title="收方" :bordered="false"> <a slot="extra" @click="receiverAddress()">收件人地址簿</a>
            <recipient-form ref="recipient" :showSubmit="false" :debit="'1'" />
          </a-card>
        </a-col>
      </a-row>

      <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24"> 
          <a-card :bordered="false" title="物品信息" :style="{ height: '100%' }"> <a slot="extra" @click="orderGoods()">企业商品列表</a>
            <goods-form ref="goods" :showSubmit="false" />
          </a-card>
        </a-col>

        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="订单信息" :style="{ height: '100%' }">
            <order-form ref="order" :showSubmit="false" />
          </a-card>
        </a-col>
      </a-row>

      <a-modal v-model="priceDetailVisible" title="价格明细">
        <p :key="index" v-for="(item, index) in priceList">{{ item.title }}：{{ item.priceVal }}</p>
        <p>总计：{{ actualPrice }}</p>
        <template slot="footer">
          <a-button key="cancel" @click="handlePriceDetailCancel">取消</a-button>
          <a-button key="forward"  type="primary" @click="saveOrder">下单并接单</a-button>
        </template>
      </a-modal>

      <a-modal v-model="orderConfirmVisible" title="下单成功">
        <p>下单成功，请提醒调度及时安排提货</p>
        <template slot="footer">
          <a-button key="forward1"  @click="orderFreshen">刷新页面</a-button>
          <a-button key="forward"  type="primary" @click="orderConfirm">确认</a-button>
        </template>
      </a-modal>

    </div>
    <Address ref="AddressModal"/>  
    <Goods ref="GoodsModal"/>

  </div>
</template>

<script>
import moment from 'moment'
import { mixinDevice } from '@/utils/mixin'
import SendForm from './OnlineDisperseOrder/SendForm'
import RecipientForm from './OnlineDisperseOrder/RecipientForm'
import GoodsForm from './OnlineDisperseOrder/GoodsForm'
import OrderForm from './OnlineDisperseOrder/OrderForm'
import Address from './modules/Address'
import Goods from './modules/Goods'
import { onlineDisperseOrder, saveOrder, orderTransportPrice } from '@/api/order'
import { enumerationData  } from '@/api/common'
import { findListCustomer } from '@/api/manage'

export default {
  name: 'OnlineDisperseOrder',
  mixins: [mixinDevice],
  components: {
    SendForm,
    RecipientForm,
    GoodsForm,
    OrderForm,
    onlineDisperseOrder,
    Address,  
    Goods,
  },

  data() {
    return {
      loading: true,
      priceDetailVisible: false,
      priceList: {},
      orderParams: {},
      actualPrice: '',
      successMsg: '',
      customerCodeList:[],
      orderConfirmVisible:false,
      customerInfo:[],
      mainForm: this.$form.createForm(this),
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
       this.$refs.order.order()
       this.$refs.goods.goods()
       this.$refs.recipient.recipient()
       this.$refs.send.send()
      this.loadEnumerationData()
    },

    customerChange($customerCode){
      if($customerCode != 0){        
        for(var i=0;i<this.customerCodeList.length;i++){
          if($customerCode == this.customerCodeList[i].code){  
                this.customerInfo = this.customerCodeList[i]                          
                break;
          }
        }
        console.log('order',this.$refs.order)
        this.$refs.order.setCustomerInfo(this.customerInfo)        
      }
       
    },

    loadEnumerationData(){        
        var enumerationParam = {tableName:'sysCOrder'}
        enumerationData(enumerationParam)
         .then(res => {
            if(res.code == 0){
              
              this.$refs.send.sendLoad(res.result)
              this.$refs.recipient.recipientLoad(res.result)
              this.$refs.goods.goodsLoad(res.result)

              this.$refs.order.orderLoad(res.result)

            }else{
              this.$notification.error({
                message: '订单加载失败',
                description: res.message
              })
            }
          })


        var enumerationParam = {tableName:'sysCOrderGoods'}
        enumerationData(enumerationParam)
         .then(res => {
            if(res.code == 0){

              this.$refs.order.goodsLoad(res.result)

            }else{
              this.$notification.error({
                message: '订单加载失败',
                description: res.message
              })
            }
          })

          //企业识别码
          var parameter = { "type": 1,"status":1}
          findListCustomer(parameter)
          .then(res => {
            if(res.code == 0){
              this.customerCodeList = res.result;            
            }else{
              this.$notification.error({
                message: '获取企业失败，请稍后重试',
                description: res.message
              })
            }
          }) 

     },

    shipperAddress(){      
       var tbCustomerId = this.mainForm.getFieldValue('tbCustomerId')
       if(tbCustomerId != 0){
          this.$refs.AddressModal.address(this.customerInfo,1)      
       }else{
          this.$notification.error({
                message: '企业客户',
                description: "请选择下单客户"
          })
       }
       
    },
    receiverAddress(){
      var tbCustomerId = this.mainForm.getFieldValue('tbCustomerId')
      if(tbCustomerId != 0){
          this.$refs.AddressModal.address(this.customerInfo,2)        
      }else{
          this.$notification.error({
                message: '企业客户',
                description: "请选择下单客户"
          })
      }
      
    },
    orderGoods(){
      var tbCustomerId = this.mainForm.getFieldValue('tbCustomerId')
       if(tbCustomerId != 0){
           this.$refs.GoodsModal.goods(this.customerInfo)            
      }else{
          this.$notification.error({
                message: '企业客户',
                description: "请选择下单客户"
          })
      }        
    },
    validate() {
      const {
        $refs: { send, recipient, goods, order },
        $notification,
      } = this

      const sendForm = new Promise((resolve, reject) => {
        send.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          values.shipperProvinceCode = values.geoCode[0]
          values.shipperCityCode = values.geoCode[1]
          values.shipperDistrictCode = values.geoCode[2]
          values.shipperStreetCode = values.geoCode[3]

          if(values.shipperProvinceCode == undefined || values.shipperCityCode == undefined || values.shipperDistrictCode == undefined || values.shipperStreetCode == undefined){
              this.$notification.error({
                message: '寄方',
                description: "请选择城市区域"
              })
              return false
          }
          delete values.geoCode
          console.log(values)
          resolve(values)
        })
      })

      const recipientForm = new Promise((resolve, reject) => {
        recipient.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          values.receiverProvinceCode = values.geoCode[0]
          values.receiverCityCode = values.geoCode[1]
          values.receiverDistrictCode = values.geoCode[2]
          values.receiverStreetCode = values.geoCode[3]
          console.log('receiverStreetCode',values.receiverStreetCode)

          if(values.receiverProvinceCode == undefined || values.receiverCityCode == undefined || values.receiverDistrictCode == undefined || values.receiverStreetCode ==undefined){
              this.$notification.error({
                message: '收方',
                description: "请选择城市区域"
              })
              return false
          }
          delete values.geoCode
          console.log(values)
          resolve(values)
        })
      })

      const goodsForm = new Promise((resolve, reject) => {
        goods.form.validateFields((err, values) => {
          console.log('goodsForm', err)
          if (err) {
            reject(err)
            return
          }
          console.log(values)
          resolve(values)
        })
      })

      const orderForm = new Promise((resolve, reject) => {
        order.form.validateFields((err, values) => {
          console.log('orderForm', err)
          if (err) {
            reject(err)
            return
          }
          if (values.isImport == 1) {
            if (values.depositReceipt == '') {
              this.$notification['error']({
                message: '进口证件',
                description: '请上传关单',
              })
              return false
            }
            if (values.pratique == '') {
              this.$notification['error']({
                message: '进口证件',
                description: '检验检疫证',
              })
              return false
            }
            if (values.depositReceipt == '') {
              this.$notification['error']({
                message: '进口证件',
                description: '核酸检测报告',
              })
              return false
            }
          }
          if(values.pickupBillsBool == 0){
             values.pickupBills = ''
          }
          console.log('insuredPrice',values.insuredPrice)
          if(values.insuredPrice == null){
             values.insuredPrice = 0
          }
          console.log(values)
          resolve(values)
        })
      })

      // clean this.errors
      this.errors = []
      Promise.all([sendForm, recipientForm, goodsForm, orderForm])
        .then((list_values) => {
          console.log('list_values', list_values)
          this.orderParams = Object.assign({}, list_values[0], list_values[1], list_values[2], list_values[3])
          return orderTransportPrice(this.orderParams).then((res) => {
            console.log(res)
            if (res.code == 0) {
              console.log('res.result', res.result)
              this.priceList = res.result.priceDetailList
              this.actualPrice = res.result.actualPrice
              this.priceDetailVisible = true
            } else {
              this.$notification.error({
                message: '查询价格',
                description: res.message,
              })
            }
            return res.result
          })
        })
        .catch(() => {
          const errors = Object.assign(
            {},
            send.form.getFieldsError(),
            recipient.form.getFieldsError(),
            goods.form.getFieldsError(),
            order.form.getFieldsError()
          )
          const tmp = { ...errors }
          console.log('errorTmp', tmp)
          this.errorList(tmp)
        })
    },

    errorList(errors) {
      if (!errors || errors.length === 0) {
        return
      }
      this.errors = Object.keys(errors)
        .filter((key) => errors[key])
        .map((key) => ({
          key: key,
          message: errors[key][0],
        }))
    },

    saveOrder() {
      return saveOrder(this.orderParams).then((res) => {
        const _this = this
        if (res.code == 0) {
          this.priceDetailVisible = false
          this.orderConfirmVisible = true          
        } else {
          this.$notification['error']({
            message: '下单失败',
            description: res.message,
          })
        }
        return res.result
      })
    },
    handlePriceDetailCancel(){
      this.priceDetailVisible = false
    },
    orderFreshen(){
        this.orderConfirmVisible = false
        this.loadData()        
    },
    orderConfirm(){
        this.orderConfirmVisible = false
    },
  },

  created() {},
}


</script>

