<style lang="less" scoped>
/deep/ .ant-table-body {
  box-sizing: border-box;
}
.ant-form-item-label {
  width: 90px !important;
  text-align: left !important;
}
/deep/ .ant-form-item-label{
  text-align: left;
}
.table-wrapper {
  margin-bottom: 50px;
}
/deep/ .ant-table-tbody tr {
  background-color: #fff;
}
  .ant-col-md-7 {
    padding-right: 0 !important;
  }

.formInquire {
  padding-top: 25px;
  background: #fff;
  padding-left: 20px;
  /deep/ .ant-form-item-label {
    width: 80px !important;
  }
}
/deep/ .ant-table-row {
  /deep/ td {
    // text-align: left !important;
    background: pink;
  }
}
.ant-table table {
  table-layout: fixed;
}
.ant-table-tbody tr td span {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.dislodge-script{
 /deep/ .ant-form-item-label label::after{
    content:'' !important;
  }
}
</style>
<template>
    <a-modal
        title="地址"
        :width="800"
        :visible="visible"  
        @cancel="handleCancel"
        footer=""
        :destroyOnClose=true
    >

        <div class="table-page-search-wrapper">
                <a-form layout="inline" class="formInquire">
                    <a-row :gutter="48" class="orderTop">
                        <a-col :md="7" :sm="12">
                            <a-form-item label="手机号/姓名">
                                  <a-input v-model="queryParam.searchContent" placeholder="手机号/姓名"  />
                            </a-form-item>
                        </a-col>                                
                    </a-row>

                    <a-row :gutter="48">
                        <a-col>
                        <span class="table-page-search-submitButtons">
                        <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                        <a-button style="margin-left: 10px" @click="() => (queryParam = {})">重置</a-button>
                        </span>
                    </a-col>
                    </a-row>
                </a-form>
        </div>
    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :page-size="10"
      :scroll="{ y: 420, x: 'max-content' }"
      class="tableOrder"
    >
      <div slot="address" slot-scope="text, record">
        <template> {{ record.provinceName }}{{ record.cityName }}{{ record.districtName }}{{record.streetName}}{{ record.addressDetail }} </template>
      </div>
     
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="setAddress(record)">选择</a>  
          <a-divider type="vertical" />         
          <a @click="delAddress(record)">删除</a>  
        </template>
      </span>
    </s-table>

    </a-modal>
</template>   
<script>
import { STable, Ellipsis } from '@/components'
import { getAddressList,delAddress } from '@/api/order'


export default {
  name: 'AddressList',
  components: {
    STable,
    Ellipsis,
  },
  data() {
    return {
      mdl: {},     
      // 查询参数
      queryParam: {},
      visible:false,
      customerInfo:[], 
      addressType:1,  
      // 表头
      columns: [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'realname',
          fixed: 'left',
          width: '100px',
        },
        {
          title: '手机号',
          align: 'center',
          width: '100px',
          dataIndex: 'phoneNumber',
        },
        {
          title: '地址',
          align: 'center',
          width: '500px',
          dataIndex: 'addressDetail',
          scopedSlots: { customRender: 'address' },
        },             
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' },
        },
      ],

      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {                
        this.queryParam.addressType = this.addressType
        console.log('customerId', this.customerInfo.id)       
        this.queryParam.tbCustomerId = this.customerInfo.id        
         return getAddressList(Object.assign(parameter, this.queryParam)).then((res) => {         
          if(res.code == 0){
             return res.result
          }else{
             this.$notification.error({
              message: '加载失败',
              description: res.message,
            })
          }

        })
      },

    }
  },
  filters: {},
  created() {

  },
  methods: {
    address(customerInfo,addressType) {
     console.log('customerInfo',customerInfo)
     if(customerInfo.id == undefined){
       this.$notification.error({
          message: '企业客户',
          description: '请选择企业客户',
        })
        return false
      }      
      this.visible = true     
      this.addressType = addressType
      this.customerInfo = customerInfo
    },     
    handleCancel(){
       this.visible = false
    },     
    setAddress(record){
        console.log('record',record)
        console.log('parent',this.$parent)
        if(this.addressType == 1){
            this.$parent.$refs.send.form.setFieldsValue({              
              shipperName: record.realname,
              shipperPhoneNumber: record.phoneNumber,
              geoCode: [record.provinceCode, record.cityCode, record.districtCode,record.streetCode],
              shipperAddressInfo: record.addressDetail, 
              isShipperAddress:0,
            }) 
          this.visible = false
        }else{
            this.$parent.$refs.recipient.form.setFieldsValue({              
              receiverName: record.realname,
              receiverPhoneNumber: record.phoneNumber,
              geoCode: [record.provinceCode, record.cityCode, record.districtCode,record.streetCode],
              receiverAddressInfo: record.addressDetail,   
              isReceiverAddress:0,
            }) 
            this.visible = false
        }                    
    },
    delAddress(record){
      let that = this
      this.$confirm({
        title: "删除地址",
        content: '是否删除地址？',
        okText: '是',
        cancelText: '否',
        onOk() {
            var param = {"id":record.id}
            return delAddress(param).then((res) => {         
              if(res.code == 0){
                that.$refs.table.refresh(true)               
              }else{
                that.$notification.error({
                  message: '删除失败',
                  description: res.message,
                })
              }
            }) 
        },
        onCancel() {},
      }) 

    },

  },
}
</script>
