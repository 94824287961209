<style lang="less" scoped>
.form {
  display: flex;
  flex-wrap: wrap;
    .ant-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    }
  > .ant-form-item {
    width: 50% !important;
    min-height: 60px !important;
    display: flex !important;
    flex-wrap: wrap;
    margin-right: 0 !important;
    /deep/ .ant-form-item-control-wrapper {
      width: 70%;
    }
    .ant-input-number {
      width: 86%;
    }
  }
  /deep/ .ant-input {
    width: 87%;
  }
}
/deep/ .ant-form-item-label {
  width: 90px;
  text-align: left !important;
}
.length-width-and-height {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .ant-form-item {
    width: 100%;
    .ant-input-number {
      width: 70%;
    }
  }
  /deep/ .ant-form-item-control-wrapper {
    width: 70%;
    .ant-input-number-input {
      width: 100%;
    }
  }
  /deep/ .ant-form-item-label {
    width: 90px !important;
    text-align: center !important;
  }
  .ant-col-sm-12 {
    padding: 0 !important;
  }
  .long {
    /deep/ .ant-form-item-label {
      text-align: left !important;
      text-indent: 12px;
    }
  }
}
.total-volume {
  /deep/ .ant-input {
    width: 100% !important;
  }
 /deep/ .ant-form-item-control{
  width: 100%;
 }
  /deep/ .ant-input-affix-wrapper {
    width: 61% !important;
      input{
      border-radius: 5px 0 0 5px ;
      }
  }
}
.goods-input{
 /deep/ .ant-form-item-children{
    display: flex;
    .ant-input-number{
      border-radius: 5px 0 0 5px ;
      width: 61% !important;
    }
    .ant-btn{
      border-radius:0 5px 5px 0;
      border-left: none;
      width: 25% !important;
    }
  }
}
</style>
<template>
  <a-form layout="inline" @submit="handleSubmit" :form="form" class="form">
    <a-form-item label="物品名称">
      <a-input v-decorator="['goodsName', { rules: [{ required: true, message: '物品名称' }] }]" placeholder="10字以内"  :maxLength="10"/>
    </a-form-item>
    <br />
    <a-form-item label="总件数">
      <a-input-number
        :min="1"
        :max="99999"
        :step="1"       
        v-decorator="['goodsNum', { initialValue: '', rules: [{ required: true, message: '总件数' }] }]"
      />
    </a-form-item>
    <br />
    <a-form-item label="总重量" class="goods-input">
      <a-input-number
        :min="0.1"
        :max="99999"
        :step="0.1"
        :precision="1"
        v-decorator="['weight', { rules: [{ required: true, message: '重量' }] }]"
      />
      <a-button disabled>KG</a-button>
    </a-form-item>
    <br />
    <a-form-item label="体积" class="goods-input">
      <a-input-number
        :min="0.00"
        :max="99999"
        :step="0.01"
        :precision="2"
        @blur="computeVomlue()"
        v-decorator="['volume', { initialValue: '0.00', rules: [{ required: true, message: '体积' }] }]"
      />
      <a-button disabled>M3</a-button>
    </a-form-item>
    <br />

    <a-row :gutter="48" class="length-width-and-height">
      <a-col :md="12" :sm="12" class="formwidth goods-input">
        <a-form-item label=" 长" class="long">
          <a-input-number
            :min="0"
            :max="99999"
            :step="1"
            :precision="0" 
            @blur="compute"
            placeholder="长 cm"
            v-decorator="['length', { initialValue: ''}]"
          />
          <a-button disabled>CM</a-button>
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="12" class="goods-input">
        <a-form-item label="宽 " class="long">
          <a-input-number
            :min="0"
            :max="99999"
            :step="1"
            :precision="0" 
            @blur="compute"
            placeholder="宽 cm"
            v-decorator="['width', { initialValue: ''}]"
          >
          </a-input-number>
          <a-button disabled>CM</a-button>
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="12" class="goods-input">
        <a-form-item label="高 " class="long">
          <a-input-number
            :min="0"
            :max="99999"
            :step="1"
            :precision="0" 
            @blur="compute"
            placeholder="高 cm"
            v-decorator="['high', { initialValue: ''}]"
          />
          <a-button disabled>CM</a-button>
        </a-form-item>
      </a-col>
     

      <a-col :md="12" :sm="12" class="goods-input">
        <a-form-item label="包装" class="goods-input">
          <a-input  v-decorator="['packingDesc', { rules: [{ required: true, message: '包装' }] }]" maxlength="10" placeholder="10字以内" id="packingDesc" />         
        </a-form-item>
      </a-col>

      <a-col :md="12" :sm="12" class="goods-input">
        <a-form-item label="保存商品" class="goods-input">
           <a-radio-group      
            v-decorator="['isOrderGoods', { initialValue: 1, rules: [{ required: true }] }]"
            >
            <a-radio :key="index" v-for="(item, index) in isOrderGoodsData" :value="item.enumValue">
              {{ item.enumName }}</a-radio
            >
          </a-radio-group>
                
        </a-form-item>
      </a-col>
    </a-row>

  
  </a-form>
</template>    
<script>
export default {
  name: 'GoodsForm',
  components: {},
  props: {
  
  },
  data() {
    return {         
      isOrderGoodsData:[],     
      form: this.$form.createForm(this),
    }
  },
  mounted() {
    const {
      form: { setFieldsValue },
    } = this    
    this.form.resetFields()
  },
  methods: {
    goods(){      
      this.form.resetFields()
    },
    goodsLoad(res){
        this.isOrderGoodsData = res.orderAssistInfoSave
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$notification['error']({
            message: 'Received values of form:',
            description: values,
          })
        }
      })
    },

    computeVomlue() {
      var volume = this.form.getFieldValue('volume')     
      var goodsNum = this.form.getFieldValue('goodsNum')
      var computeVolume = this.computeVolumeByLwh()
      if (computeVolume != volume) {
        this.form.setFieldsValue({ width: '', high: '', length: '' })
      }
      this.computePalletNum()       
    },
    computeVolumeByLwh() {
      var length = this.form.getFieldValue('length')
      var width = this.form.getFieldValue('width')
      var high = this.form.getFieldValue('high')
      var volume = this.form.getFieldValue('volume')      
      if (length != null && width != null && high != null) {
        var volume = (length * width * high) / 1000000
        if (volume <= 0.01) {
          volume = '0.00'
        }
        if (volume > 99999) {
          volume = '99999'
        }
        volume = this.fixedTwo(volume)        
      }
      return volume
    },
    compute() {
      var goodsNum = this.form.getFieldValue('goodsNum')
      var volume = this.computeVolumeByLwh()
      console.log('volume',volume)

      if (volume != 0) {
        this.form.setFieldsValue({ volume: volume})
        this.computePalletNum() 
      }
    },
    fixedTwo(number){
        let tempVal = parseFloat(number).toFixed(2)        
        return tempVal
    },
    computePalletNum(){        
       let isPalletFee  =  this.$parent.$parent.$parent.$parent.$refs.order.form.getFieldValue('isPalletFee')       
       if(isPalletFee == 1){
         this.$parent.$parent.$parent.$parent.$refs.order.palletFeeChange(1)
       }       
    }
  },
}
</script>
